<template>
  <div class="login">
    <div>
<!--      <img src="../../assets/images/logo_1.jpg" width="400">-->
    </div>
    <div class="body">
      <van-form @submit="onSubmit">
        <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
            v-model="password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import {Login} from "@/api/login";
import { Toast } from 'vant';
export default {
  name: "index",
  data() {
    return {
      username: '',
      password: '',
      UserInfo: {
        User_ID: '',
        Dept_ID: '',
        Role_ID: '',
        Staff_Name: '',
      },
    }
  },
  methods:{
    async onSubmit() {
      const {data} = await Login({UserName: this.username, Password: this.password})
      if (data.code === 200) {
        console.log("登录成功")
        let Info = data.data;
        this.UserInfo = {
          User_ID: Info.User_ID,
          Dept_ID: Info.Dept_ID,
          Role_ID: Info.Role_ID,
          Staff_Name: Info.Staff_Name
        }
        console.log(Info)
        this.$store.commit("setUser", this.UserInfo.User_ID)
        this.$store.commit("setRole", this.UserInfo.Role_ID)
        this.$store.commit("setDept", this.UserInfo.Dept_ID)
        this.$store.commit("setStaff", this.UserInfo.Staff_Name)
        this.$store.commit("setToken", Info.token)

        await this.$router.push({
          path: '/index'
        })
      } else {
        Toast('账号或密码错误');
      }
    },
  }
}
</script>

<style scoped>
  .body{
    margin-top: 50px;
  }
</style>